
export const formStyles = {

    // layout
    formControlLayout: {
        inner: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        label: {
            flexBasis: ["100%", null, "20%"],
        },
        field: {
            flexGrow: 1
        }
    },

    buttonGroup: {
        margin: 0,
        minHeight: 80,
        alignItems: 'center',
        tansfrom: 'translate(-100%)',
    },

    fieldsGroup: {
        '.field--captcha,.field--recaptcha': {
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 0,

            '.ui-form__formControlField': [
                null,
                {
                    marginRight: 150,
                    marginBottom: -80,
                }
            ],
        },
    },


    // fields
    textField: {
        input: {
            variant: 'capitalAzur',
            size: 'large',
        }
    },

    textAreaField: {
        input: {
            variant: 'capitalAzur',
        },
    },

    selectField: {
        input: {
            variant: 'capitalAzur',
        },
    },
    
    /*
    //
    ReCaptchaField: {
        display: 'flex',
        justifyContent: 'flex-end',

        '.ui-form__formControlField': [
            null,
            {
                marginRight: 150,
                marginBottom: -80,
            }
        ],
    },*/


    // buttons
    submitButton: {
        variant: 'buttons.primary',
    },
}