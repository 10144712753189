import React from 'react'
import {
    Button,
    Flex,
    Layer,
    Text,
} from 'vactory-ui'


export const SuccessMessageLayer = ({title="success!", message, link, dismiss, ...rest}) => {

    return (
        <Layer onClickOutside={() => dismiss(true)}>
            <Flex p="medium" boxShadow={4} flexDirection="column" bg="white" borderRadius="small" maxWidth="400px">
                <Flex mb="medium"><Text level="1" fontSize="18px">{title}</Text></Flex>
                <Flex flexDirection="column" alignItems="center">
                    { message && <Text>{message}</Text> }
                    { !message && rest.children }
                </Flex>
                <Flex mt="medium" justifyContent="flex-end">
                    <Button borderRadius="rounded" mx="small" variant="danger"
                            onClick={() => dismiss(true)}>close</Button>
                </Flex>
            </Flex>
        </Layer>
    )
};