import React from 'react';
import {useTheme} from 'styled-components';
import {
    Flex,
    Icon,
    Layer,
    Link,
} from 'vactory-ui';
import { useMenu } from 'vactory-gatsby-core';

const ToolLink = (props) => {
    const {
        title,
        url,
        options: {
            attributes: {icon, ...attributes}
        }
    } = props;

    return (<Link
        variant='buttons.white'
        borderRadius={null}
        __css={{
            pl: 14,
            border: 0,
            '&&:first-child': {
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
            },
            '&&:not(:first-child):not(:last-child)': {
                borderRadius: '0',
            },
            '&&:last-child': {
                borderTopLeftRadius: '0',
                borderTopRightRadius: '0',
            },
        }}
        href={url}
        {...attributes}
    >
        {icon && <Icon size={20} mr={14} name={icon} />}
        {title}
    </Link>)
}

export const FloatingToolbox = (props) => {
	const {breakpoints} = useTheme();
    const links = useMenu('toolbox');
    const hiddenClassName = 'hide'
    const [refVisible, setRefVisible] = React.useState(false);
    const layerRef = React.useRef(null);

    const layerStyle = {
        '@media print': {
            display: 'none',
        },
        maxWidth: 225,
        left: 'auto',
        borderRadius: 8,
        transition: '.2s ease-in-out .2s',
        [`&.${hiddenClassName}:not(:hover)`]: {
            boxShadow: '-20px 10px 34px 0px rgba(0,0,0,.17)',
            transform: 'translate(calc(100% - 47px), -50%)',
        }
    }

    React.useEffect(() => {

        if (!refVisible)
            return;
        
        const updateState = () => {
            let conatinerWidth = parseInt(breakpoints[0]);
            let reverseBreakpoints = breakpoints.slice().reverse();
            for (const bp of reverseBreakpoints) {
                if (window.innerWidth >= parseInt(bp)) {
                    conatinerWidth = parseInt(bp);
                    break;
                }
            }

            let boxWidth = layerRef.current.offsetWidth;
            let availableSpace = (window.innerWidth - conatinerWidth) / 2 + 25; // allow an overlapping of 25px with container

            if (boxWidth >= availableSpace)
                layerRef.current.classList.add(hiddenClassName)
            else
                layerRef.current.classList.remove(hiddenClassName)
        }

        updateState();
        window.addEventListener('resize', updateState);
        return () => window.removeEventListener('resize', updateState);

    }, [refVisible, breakpoints]);

    return <Layer
        ref={el => { layerRef.current = el; setRefVisible(!!el); }}
        animation='fadeIn'
        full='horizontal'
        position="right"
        modal={false}
        plain={true}
        css={layerStyle} >
            <Flex 
                __css={{
                    maxWidth: 225,
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    borderRadius: 8,
                    border: '1px solid white',
                    // boxShadow: '10px 12px 14px -5px rgba(191, 191, 191, .67)',
                    py: 0,
                }} >

                {links.map( (link) => (
                    <ToolLink
                        key={link.id}
                        {...link}
                    />
                ))}

            </Flex>
    </Layer>
}