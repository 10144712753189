import { ThreeColumnsContainer as EventsThreeColumnsContainer, TwoColumnsContainer as EventsTwoColumnsContainer, ListContainer as EventsListContainer, SliderContainer as EventsSliderContainer } from "vactory-gatsby-events";
import { PostsContainer as GlossaryPostsContainer } from "vactory-gatsby-glossary";
import { ThreeColumnsContainer as JobAdsThreeColumnsContainer, TwoColumnsContainer as JobAdsTwoColumnsContainer, ListContainer as JobAdsListContainer } from 'vactory-gatsby-job-ads';
import { DefaultContainer as MapDefaultContainer } from "vactory-gatsby-map";
import { ThreeColumnsContainer as NewsThreeColumnsContainer, TwoColumnsContainer as NewsTwoColumnsContainer, ListContainer as NewsListContainer } from "vactory-gatsby-news";
import { ThreeColumnsContainer as TenderThreeColumnsContainer, TwoColumnsContainer as TenderTwoColumnsContainer, ListContainer as TenderListContainer } from "vactory-gatsby-tender";
import { TabsContainer } from "vactory-gatsby-ui";
import { AccordionContainer } from "vactory-gatsby-ui";
import { AncreContainer } from "vactory-gatsby-ui";
import { BannerAContainer } from "vactory-gatsby-ui";
import { WebformWidgetContainer } from 'vactory-gatsby-webform';
import { ExampleWidgetContainer, FullSliderContainer, ContentWithBgContainer, ContentContainer, ServiceCardsContainer, CapitalAzurAccordionContainer, TextContentContainer, WebformContainer, GouvernanceWidgetContainer, TestimonyWidgetContainer, KeyFiguresWidgetContainer, VideoAskContainer } from 'capital-azur';
const widgets = {
  "vactory_events:three-columns": EventsThreeColumnsContainer,
  "vactory_events:two-columns": EventsTwoColumnsContainer,
  "vactory_events:list": EventsListContainer,
  "vactory_events:slider": EventsSliderContainer,
  'vactory_glossary:list': GlossaryPostsContainer,
  'vactory_job_ads:three-columns': JobAdsThreeColumnsContainer,
  'vactory_job_ads:two-columns': JobAdsTwoColumnsContainer,
  'vactory_job_ads:list': JobAdsListContainer,
  'vactory_locator:default': MapDefaultContainer,
  'vactory_news:three-columns': NewsThreeColumnsContainer,
  'vactory_news:two-columns': NewsTwoColumnsContainer,
  'vactory_news:list': NewsListContainer,
  "vactory_tender:three-columns": TenderThreeColumnsContainer,
  "vactory_tender:two-columns": TenderTwoColumnsContainer,
  "vactory_tender:list": TenderListContainer,
  'vactory_core_ui:tabs': TabsContainer,
  'vactory_core_ui:accordion': AccordionContainer,
  'vactory_core_ui:ancre': AncreContainer,
  'vactory_banner:bannerA': BannerAContainer,
  'vactory_webform:webform': WebformWidgetContainer,
  'vactory_bootstrap:5': ExampleWidgetContainer,
  'capital_azur_dynamic_field:full-slider': FullSliderContainer,
  'capital_azur_dynamic_field:content': ContentContainer,
  'capital_azur_dynamic_field:content-with-bg': ContentWithBgContainer,
  'capital_azur_dynamic_field:service-cards': ServiceCardsContainer,
  'capital_azur_dynamic_field:capital-azur-accordion': CapitalAzurAccordionContainer,
  'capital_azur_dynamic_field:text-content': TextContentContainer,
  'capital_azur_dynamic_field:empty-df': WebformContainer,
  'capital_azur_dynamic_field:gouvernance': GouvernanceWidgetContainer,
  'capital_azur_dynamic_field:key-figures': KeyFiguresWidgetContainer,
  'capital_azur_dynamic_field:videoask': VideoAskContainer,
  'capital_azur_dynamic_field:testimony': TestimonyWidgetContainer
};
export default widgets;