import React from 'react';
import {
	Box,
	Container,
    Flex,
    Footer,
    Link as VuiLink,
	Nav,
    Navs,
    Text,
} from 'vactory-ui';
import {Link as GatsbyLink} from 'vactory-gatsby-ui'
import { useMenu } from 'vactory-gatsby-core'
import { SocialIcon } from './SocialIcon'
import void_logo from '../images/void-logo-mini.svg'


const FooterNav = props =>  <Nav
    as={GatsbyLink}
    to={props.href}
    sx={{
    fontSize: 11,
    lineHeight: '20px',
    fontWeight: 'medium',
    letterSpacing: .21,
    textTransform: 'uppercase',
    color: '#000',
    padding: 0,
    paddingX: [0, null, null, '16px'],
    borderRight: ['0', null, null, '1px solid rgba(0,0,0,0.19)'],
    m:0,
    mb: ['12px', null, null, '0'],
    '&:hover': {
        color: 'primary',
    },
    '&:last-child' : {
        borderRight: 0,
        pr: 0,
        m: 0
    }
    }}
    {...props}/>

const FooterNavs = props =>  <Navs
    sx={{
        p: 0,
        m:0,
        width:"100%",
        alignItems: 'baseline',
        flexDirection: ['column !important', null, null, 'row !important'],
    }}
    {...props}
    />


export const CapitalAzurFooter = () => {
    const menuItems = useMenu('footer');

    return (
    <Footer sx={{
        backgroundColor: '#fff',
        fontFamily: 'montserrat',
        display: 'flex',
        flexDirection: 'column',
        borderTop: '2px solid #dee2e6',
    }}
    className="vf-footer1">
        <Container>
            <Flex sx={{
                width: '100%',
                flexDirection: 'column',
            }}>
                <Flex sx={{
                    fontSize: "14px",
                    color: '#000000',
                    flexDirection: ['column', null, null, 'row'],
                    justifyContent: 'space-between',
                    alignItems: ['flex-start', null, null, 'center'],
                    p: '23px 12px',
                }}>
                    <Flex className="vf-footer1__social-media" sx={{
                            mb: ['16px', '0px'],
                            flexWrap: 'wrap',
                            width: ['100%', null, null, 'auto'],
                            justifyContent: 'center',
                        }}>
                        <Text display={{lg: 'none'}} sx={{
                            mb: 7.5,
                            flex: '1 100%',
                            textAlign: 'center',
                            fontWeight: 'semiBold',
                        }}>Follow us</Text>
                        <SocialIcon name="youtube" link="#" />
                        <SocialIcon name="linkedin" link="#" />
                        <SocialIcon name="facebook" link="#" />
                    </Flex>
                    <Flex className="vf-footer1__nav-menu">
                        <FooterNavs>
                            {menuItems.map(
                                item => <FooterNav
                                    key={item.id}
                                    href={item.url}
                                    { ...('attributes' in item.options ? item.options.attributes : {})}>
                                {item.title}
                                </FooterNav>
                            )}
                        </FooterNavs>
                    </Flex>
                </Flex>

                <Flex 
                    display={{_: 'none !important', lg: 'flex !important'}}
                    sx={{
                        fontSize: "11px",
                        color: 'lightBlue',
                        flexDirection: ['column', 'row'],
                        justifyContent: 'space-between',
                        p: '23px 12px',
                        borderTop: '1px solid',
                        borderTopColor: 'gray400',
                    }}
                    className="vf-footer1__copytight"
                >
                    <Box mb={['12px', '0']}>©2020 CAPITAL AZUR</Box>
                    <Flex alignItems="center">
                        Conception et Développement: 
                        <VuiLink border={0} fill={0} padding={0} variant={null} ml={10} href={'https://void.fr'} target="_blank">{/* eslint-disable-line react/jsx-no-target-blank */}
                            <img src={void_logo} alt="The Void Logo" width={50} height={13.5} />
                        </VuiLink>
                    </Flex>
                </Flex>

            </Flex>
        </Container>
    </Footer>
    )
}
