import React from 'react'
import {
    Box,
    Flex,
    Heading,
    Text,
} from 'vactory-ui'
import {CapitalCardButton} from 'capital-azur/src/components'

export const SearchResultCard = ({ title, excerpt, url, ...rest }) => {

    return <Box sx={{
            boxShadow: 'cards',
            marginY: 'xlarge',
            padding: 'xlarge',
            background: 'white',
        }} {...rest}>
        <Flex sx={{
            justifyContent: ['space-between', null, 'flex-start'],
            alignItems: ['flex-start', null, 'flex-end'],
            flexDirection: ['column', null, 'row']
        }}>
            <Box>
                <Heading level={4}>{title}</Heading>
                <Text>{excerpt}</Text>
            </Box>
            <Box mt={['medium', null, 0]} ml="auto" flexShrink={0}>
                <CapitalCardButton href={url}>Voir La Suite</CapitalCardButton>
            </Box>
        </Flex>
    </Box>
}
