import React from 'react'
import {
    Flex,
    Input,
    Label,
    Select,
    Text,
} from 'vactory-ui'


export const RequiredAsterisk = () => <Text mx={5} as="span">*</Text>

export const ErrorMessage = props => <Text color="danger500" mt="xxsmall" fontSize="13px" {...props}/>

export const FormField = ({ name, label, required=false, error, fieldRef, type='text', ...rest }) => {
    // TODO: rename it so it reflects that this is a text/textarea/number input field
    const {pattern, ...inputProps} = rest;

    return (
    <Flex my="small" alignItems="center" flexWrap="wrap">
        <Label width={ 1/4 } htmlFor={name}>
            {label}
            {required && <RequiredAsterisk/>}
        </Label>
        <Input
            variant="capitalAzur"
            type={type}
            name={name}
            id={name}
            size='large'
            status={error ? 'danger' : null}
            ref={fieldRef}
            width={ 3/4 }
            sx={{
                flex: '1',
            }}
            {...inputProps}
        />
        {error && <ErrorMessage sx={{ flex: '1 auto', maxWidth: '75%', ml: 'auto'}}>{error}</ErrorMessage>}
    </Flex>
    )
}

export const FormSelect = ({ name, label, options=[], required=false, error, fieldRef, ...rest }) => {
    return (
        <Flex my="small" alignItems="center" flexWrap="wrap">
            <Label width={ 1/4 } htmlFor={name}>
                {label}
                {required && <RequiredAsterisk/>}
            </Label>
            <Select
                options={options}
                name={name}
                id={name}
                status={error ? 'danger' : null}
                ref={fieldRef}
                width={ 3/4 }
                variant={'capitalAzur'}
                {...rest}
            />
            {error && <ErrorMessage sx={{ flex: '1 auto', maxWidth: '75%', ml: 'auto'}}>{error}</ErrorMessage>}
        </Flex>
    )
}