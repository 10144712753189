import React from 'react'
import { Picture } from 'vactory-gatsby-ui'
import {
    Box,
    Flex,
    Heading,
} from 'vactory-ui'
import { SocialIcon } from './SocialIcon'

export const Gouvernant = ({ name, image, link, ...rest }) => {

    return <Box width={[1, null, 1/3]} sx={{
            paddingX: 15,
            paddingBottom: 30
        }} {...rest}>
        <Box sx={{
                backgroundColor: 'white',
                ':hover .name': {
                    color: 'primary',
                },
            }}>
            <Box>
                <Picture 
                    file={image}
                    alt={name}
                    sizes={[
                        {
                            name: "gvrn_image_355_200",
                            media: "(min-width: 1200px)",
                        },
                        {
                            name: "gvrn_image_195_165",
                            media: "(min-width: 992px) and (max-width: 1199px)",
                        },
                        {
                            name: "gvrn_image_215_120",
                            media: "(min-width: 768px) and (max-width: 991px)",
                        },
                        {
                            name: "gvrn_image_524_295",
                            media: "(max-width: 767px)",
                        },
                    ]}
                    width={350}
                    height={197}
                    ratio={350/197} />
            </Box>
            <Flex flexDirection={'column'} p={20} pb={30}>
                <Heading className='name' variant={null} sx={{
                    marginBottom: 15,
                    cursor: 'default',
                    fontSize: '20px',
                    lineHeight: '28px',
                    fontWeight: 'semiBold',
                    letterSpacing: -.4,
                    transition: '.25s',
                }}>{name}</Heading>
                <SocialIcon mx="auto" name={link.label} href={link.href} {...link.attributes}/>
            </Flex>
        </Box>
    </Box>
}
