import React from "react"

const Post = ({post}) => (
    <div>
        <main>
            <h1>{post.title}</h1>
        </main>
    </div>
)

export default Post
