import React from 'react'
import {
    FormField,
    FormSelect,
} from 'vactory-gatsby-ui'


export const MegaFormField = ({type, ...rest}) => {
    const newProps = rest;
    newProps.label = rest.title;

    if (type === 'select')
        return (
            <FormSelect {...newProps} />
        )
    else if (type === 'textarea')
        return (
            <FormField as="textarea" type={null} {...newProps} />
        )
    else if (type === 'file')
        return (
            <FormField type={'file'} {...newProps} />
        )
    else
        return (
            <FormField {...newProps} />
        )

}