import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select, Flex } from 'vactory-ui'

const FilterSelect = ( {id, options, ...rest} ) => {
  return (
    <Flex
      className="filter-wrapper"
      sx={{
        flex: "1",
        flexDirection: "column",
        mx: "xsmall",
        mb: ['10px', '0px'],
      }}>
      <Select
        variant="filters"
        width="100%"
        id={id}
        {...rest}>
        {options.map( (option, i) => {
          return (
            <option key={option.id+i} value={option.id}>
              {option.label}
            </option>
          )
        })}
      </Select>
    </Flex>
  )
}

const PostsFormFilter = ({
  cities,
  contracts,
  professions,
  value,
  handleChangeCity,
  handleChangeContract,
  handleChangeProfession,
}) => {
  const { t } = useTranslation()

  const professionsWithAll = [{id: 'all', label: t('Profession')}, ...professions]
  const contractsWithAll = [{id: 'all', label: t('Contrat')}, ...contracts]
  const citiesWithAll = [{id: 'all', label: t('Ville')}, ...cities]
  

  return (
    <Flex my="20px" pt="10px" pb="30px" flexDirection={['column', 'row']}>
        <FilterSelect
          id="job-ads-professions"
          options={professionsWithAll}
          onBlur={null}
          onChange={(e) => {
            handleChangeProfession(e.currentTarget.value)
          }}
          defaultValue={value} />

        <FilterSelect
          id="job-ads-contracts"
          options={contractsWithAll}
          onBlur={null}
          onChange={(e) => {
            handleChangeContract(e.currentTarget.value)
          }}
          defaultValue={value} />

        <FilterSelect
          id="job-ads-cities"
          options={citiesWithAll}
          onBlur={null}
          onChange={(e) => {
            handleChangeCity(e.currentTarget.value)
          }}
          defaultValue={value} />
    </Flex>
  )
}

export default PostsFormFilter
