import React from 'react'
import { Box, Flex, Button, Heading } from 'vactory-ui'
import { Link, Wysiwyg } from 'vactory-gatsby-ui'
import { useTranslation } from 'react-i18next'

const CardTitle = ( props ) => <Heading
    level="4"
    __css={{
      color: 'inherit',
      textTransform: 'uppercase',
      fontSize: ['16px', null, '18px', null],
      lineHeight: '28px',
      fontWeight: 600,
      letterSpacing: '0',
      marginBottom: '15px',
    }}
  {...props} />

const CardDate = ( props ) => <Box
    __css={{
      fontSize: 16,
      lineHeight: '24px',
      color: '#9B9B9B',
    }}
  {...props} />

const CardTag = ( props ) => <Box
    __css={{
      color: 'white',
      bg: 'darkBlue',
      fontSize: 10,
      lineHeight: '20px',
      borderRadius: 20,
      py: 2,
      px: 10,
      mx: 5,
      my: 0,
    }}
  {...props} />

export const CardTender = (props) => {
  const { t } = useTranslation()

  const { title, reference, date, excerpt, url } = props
  const formUrl = url + '/form/'

  return (
    <Box as="article" sx={{
        borderRadius: 8,
        boxShadow: 'cards',
        background: 'white',
        marginY: 30,
        ':hover .title': {
          color: 'primary',
        },
      }}>
      <Box p="medium">
        <CardTitle className="title">{title}</CardTitle>
        <Flex>
          <CardTag>{reference}</CardTag>
          <CardDate>{date}</CardDate>
        </Flex>

        <Box mt={10} mb={30} fontSize="16px">
          <Wysiwyg html={excerpt} />
        </Box>

        <Button
          variant="buttons.white"
          as={Link}
          to={formUrl}
          target={'_blank'}
          download >
          {t('Télécharger le dossier')}
        </Button>
      </Box>
    </Box>
  )
}
