import React from 'react'
import {Flex, List, Item, Box, Label, Select} from 'vactory-ui'
import classnames from 'classnames'
import AnchorLink from "react-anchor-link-smooth-scroll"
import {useTranslation} from "react-i18next"
import { Waypoint } from 'react-waypoint'
import Scrollspy from 'react-scrollspy'

export const Ancre = ({items}) => {
    const [shouldStick, setShouldStick] = React.useState(false);
    const [navsMinHeight, setNavsMinHeight] = React.useState();
    const desktopRef = React.createRef();
    const mobileRef = React.createRef();
    const {t} = useTranslation();

    const layout = {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
    };

    const jumpTo = (id) => {
        const $anchor = document.getElementById(id);
        $anchor.click();
    };

    React.useEffect(
        () => setNavsMinHeight(desktopRef.current.clientHeight || mobileRef.current.clientHeight),
        [desktopRef, mobileRef])

    return <Box style={{minHeight:  shouldStick ? navsMinHeight : null }}>
        <Waypoint onLeave={() => setShouldStick(true)} onEnter={() => setShouldStick(false)} />
        <Box sx={{
            boxShadow: '0 5px 16px 0 rgba(0,0,0,.1)',
            backgroundColor: 'white',
            transition: '.5s',
            '&.stuck': {
                position: 'fixed',
                top: 0,
                width: '100%',
                zIndex: 1,
                }
            }}
            className={classnames('anchors-container', {stuck: shouldStick})} >

            {/* For Desktop */}
            <Box display={['none', null, 'block']}>
                <Flex ref={desktopRef} {...layout}>
                    <List
                        as={Scrollspy}
                        items={ items.map( i => i.id ) }
                        currentClassName="is-current"
                        offset={-40}
                        horizontal
                        variant="navs.ancre"
                        className="stripe"
                    >
                        {items.map((item, index) => {
                            return (
                                <Item
                                    key={index}
                                    variant="navs.ancre.item"
                                >
                                    <AnchorLink
                                        href={`#${item.id}`}
                                        id={`ancre-${item.id}`}
                                    >
                                        {item.title}
                                    </AnchorLink>
                                </Item>
                            )
                        })}
                    </List>
                </Flex>
            </Box>

            {/* For Mobile */}
            <Box display={['block', null, 'none']}>
                <Flex ref={mobileRef} {...layout}>
                    <Box sx={{position: "relative"}}>
                        <Label sx={{
                            position: 'absolute',
                            left: 0, right: 0, bottom: 0, top: '50%',
                            transform: 'translateY(-50%)',
                        }} htmlFor={'factory-ancre'}>{t("Sur cette page")}</Label>
                        <Select
                            variant="anchors"
                            id='factory-ancre'
                            name='factory-ancre'
                            onChange={(e) => jumpTo(e.currentTarget.value)}
                        >
                            {items.map((item, index) => {
                                return (
                                    <option key={index} value={`ancre-${item.id}`}>{item.title}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </Flex>
            </Box>
        </Box>
    </Box>
};
