
const buttonsSharedStyle = {
    fontSize: 13,
    lineHeight: '22px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    border: 0,
    borderRadius: '8px',
    boxShadow: 'buttons',
    px: 33,
    py: 14,
    transition: '.3s ease-in',
}

export const buttons = {
    primary: {
        ...buttonsSharedStyle,
        backgroundColor: 'lightBlue',
        color: 'white',
    },
    white: {
        ...buttonsSharedStyle,
        backgroundColor: 'white',
        color: 'lightBlue',
        border: '2px solid currentColor',
        '&:hover': {
            borderColor: 'currentColor',
        }
    },
    dashPrefixed: {
        background: 'transparent',
        color: 'lightBlue',
        position: 'relative',
        border: 0,
        fontWeight: 'semiBold',
        pl: [35, 48, 59],
        transition: '.3s ease-in',
        fontSize: 14,
        lineHeight: '24px',

        '&:hover': {
            color: '#0157b2',
            background: 'transparent',
        },

        '&::after': {
            content: '""',
            display: 'block',
            width: [20, 33, '44px'],
            height: '2px',
            backgroundColor: 'lightBlue',
            position: 'absolute',
            top: [12, null, '16px'],
            left: 0,
        }
    },
    underlined: {}
};