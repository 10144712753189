import React from 'react'
import { GouvernanceWidget } from './gouvernance-widget'
import get from 'lodash.get'
import { Wysiwyg } from 'vactory-gatsby-ui'

const renameClassProp = ({class: className, ...rest}) => ({className, ...rest})

export const GouvernanceWidgetContainer = ({ data }) => {
  const title = get(data, 'extra_field.title.value.#text', null)
  const intro = get(data, 'extra_field.intro.value.#text', null)

  const action = {
    link: get(data, 'extra_field.link.url', null),
    label: get(data, 'extra_field.link.title', null),
  }

  const fields = data.components.map((field) => {
    return {
      title: get(field, 'title', null),
      image: get(field, 'image.0', null),
      link: {
        label: get(field, 'link.title', null),
        href: get(field, 'link.url', null),
        attributes: renameClassProp(get(field, 'link.attributes', {})),
      }
    }
  })

  return <GouvernanceWidget
    title={title}
    intro={intro && <Wysiwyg html={intro}/>}
    gouvernants={fields}
    action={action} />
}
