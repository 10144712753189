import React from 'react'
import { Box, Flex, Text, Link as VuiLink } from 'vactory-ui'
import { useTranslation } from 'react-i18next'
import { Link as GatsbyLink } from 'vactory-gatsby-ui'

const CardTitle = ( props ) => {
  return (
    <Box
      as="h3"
      __css={{
        fontSize: ['20px', null, '22px', null],
        lineHeight: '28px',
        fontWeight: [900, null, 600 ],
        letterSpacing: -.4,
        marginBottom: 30,
      }}
      {...props}
    />
  )
}

const CardExcerpt = ({ children, ...rest }) => {
  return (
    <Text fontSize="14px" color="black800" {...rest}>
      {children}
    </Text>
  )
}

const CardHeader = ({ sx, children, ...rest }) => {
  return (
    <Box
      as="header"
      sx={sx}
      __css={{
        p: 'medium',
        marginBottom: 0,
        backgroundColor: '#00000010',
        borderBottom: '1px solid #00000020',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

const CardBody = ({ sx, children, ...rest }) => {
  return (
    <Box
      sx={sx}
      __css={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

const Card = ({ sx, children, ...rest }) => {
  return (
    <Box
      as="article"
      sx={sx}
      __css={{
        borderRadius: 8,
        boxShadow: 'cards',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        marginBottom: 40,
        width: '100%',
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

const CardTag = ( props ) => (
  <Box
    __css={{
      color: 'white',
      bg: props.color || 'darkBlue',
      fontSize: 10,
      lineHeight: '20px',
      borderRadius: 20,
      py: 2,
      px: 10,
      mx: 5,
      my: 0,
    }}
  {...props} />
)

const CardDate = ({ children }) => (
  <Box
    __css={{
      display: 'inline-flex',
      fontSize: '15px',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '500',
      color: '#9B9B9B',
      mb: 'medium',
    }}
  >
    {children}
  </Box>
)

const CardButton = ({ url, children }) => {
  return (
    <VuiLink my="xxsmall" as={GatsbyLink} variant="buttons.primary" to={url}>
      {children}
    </VuiLink>
  )
}

export const CardJobAds = (props) => (
  <Box height="100%" pb="16px">
    <CardJobAdsOneRow height="100%" {...props}/>
  </Box>
)


export const CardJobAdsOneRow = (props) => {
  const { t } = useTranslation()
  const url = props.url
  const title = props.title
  const date = props.date
  const city = props.city
  const profession = props.profession
  const profileDescription = props.profileDescription

  return (
    <Card>
      <CardHeader>
        <Flex>
          <CardTag>{profession}</CardTag>
          <CardTag sx={{ color: 'black', backgroundColor: '#fed700' }}>
            {city}
          </CardTag>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex flexDirection="column">
          <Box sx={{ p: 'medium', flexGrow: 1 }}>
            <CardTitle>{title}</CardTitle>
            <CardDate>{date}</CardDate>
            <CardExcerpt className="card-excerpt">
              {profileDescription}
            </CardExcerpt>
          </Box>
          <Flex flexWrap="wrap" justifyContent="space-between" px="medium" py="medium" mt="auto">
            <CardButton url={url + '/form/'}>{t('Postulez')}</CardButton>
            <CardButton url={url}>{t('En savoir plus')}</CardButton>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  )
}
