import React from 'react';
import { ThemeContext } from 'styled-components';
import {
    Box,
    Flex,
    Heading,
    Link,
    Paragraph,
    Slider,
    Text,
} from 'vactory-ui';
import {Link as GatsbyLink, Picture} from "vactory-gatsby-ui";
import {Dots, PrevArrow, NextArrow} from './Slider';
import {formatDate} from "capital-azur/src/utils";
import {Tag} from "./Tag";

const imageSettings = {
    sizes: [
        {
            name: "events_image_270_234",
            media: "(min-width: 1200px)",
        },
        {
            name: "events_image_210_258",
            media: "(min-width: 992px)",
        },
        {
            name: "events_image_217_258",
            media: "(min-width: 768px)",
        },
        {
            name: "events_image_454_303",
            media: "(min-width: 0)",
        },
    ],
    width: 270,
    height: 234,
}

export const TimeSpanBadge = ({start, end, bgColor, ...rest}) => {
    start = formatDate(start).split(' ');
    end = formatDate(end).split(' ');

    let largeFontSize = {
        fontSize: 30,
        lineHeight: '30px',
    };

    return <Box __css={{
            textAlign: 'center',
            position: 'absolute',
            background: bgColor || 'linear-gradient(45deg, #fb3a3a, #ff6a5b)',
            color: 'white',
            padding: 10,
            borderRadius: 8,
            top: 20,
            right: 20,
            fontSize: 16,
            lineHeight: '20px',
        }} {...rest}>
        <Box>{start[0]}</Box>
        <Box {...largeFontSize}>{start[1]}</Box>
        <Text>-</Text>
        <Box>{end[0]}</Box>
        <Box {...largeFontSize}>{end[1]}</Box>
    </Box>
}

export const Slide = ({
        image,
        date,
        description,
        title,
        tag,
        location,
        action,
    }) => {

    return <Box py={20} px={[20, null, null, 150]}>
        <Flex sx={{
            bg: 'white',
            borderRadius: 8,
            flexFlow: ['column wrap', null, 'row nowrap'],
        }}>
            <Box width={[1, null, 1/3]} sx={{
                position: 'relative',
                borderRadius: ['8px 8px 0 0', null, '8px 0 0 8px'],
                overflow: 'hidden',
                }}>
                <Box>
                    <Picture
                        file={image}
                        alt={title}
                        {...imageSettings}
                        sx={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        disableAspectRatio
                    />
                </Box>
                <TimeSpanBadge start={date.value} end={date.end_value} />
            </Box>

            <Flex width={[1, null, 2/3]} p={20} flexDirection='column' alignItems='flex-start'>
                <Heading level={3} sx={{
                    variant: 'heading.events',
                    mb: [10, null, 15],
                }}>{title}</Heading>

                <Box>
                    <Tag color='primary' py={2.5} >{tag}</Tag>
                    <Tag color='red' py={2.5} >{location}</Tag>
                </Box>

                <Paragraph sx={{
                    my: [10, null, 15],
                    variant: 'text.events',
                }}>
                    {description}
                </Paragraph>

                <Link as={GatsbyLink} sx={{
                    mt: 'auto',
                    variant: 'buttons.primary',
                }} href={action.href}>{action.label}</Link>
            </Flex>
        </Flex>
    </Box>
}

export const EventsSlider = ({events, ...rest}) => {
    const theme = React.useContext(ThemeContext);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow right={[-25, -40, -50, 'calc(50% - 950px/2)']} />,
        prevArrow: <PrevArrow left={[-25, -40, -50, 'calc(50% - 950px/2)']} />,
        appendDots: (dots) => React.cloneElement(
            Dots(dots),
            {css: {
                marginTop: '10px !important'
            }}
        ),

        responsive: [
            {
                breakpoint: parseInt(theme.breakpoints.md),
                settings: {
                    arrows: false,
                }
            }
        ]
    }
    return <Slider {...settings}>

        {events.map( (event, i) => <Slide
            key={event.id}
            title={event.title}
            description={event.excerpt}
            image={event.image}
            date={event.dateInterval}
            tag={event.category}
            location={event.city}
            action={{
                href: event.url,
                label: 'Lire Plus'
            }}
            />
        )}

    </Slider>
}
