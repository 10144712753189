import React from "react"
import classNames from "classnames"
import {Container, Row, Col} from 'vactory-ui'

const NarrowContainer = React.forwardRef( ({children}, innerRef) => {
    return (<Container ref={innerRef}><Row><Col>{children}</Col></Row></Container>)
});

const FullContainer = React.forwardRef( ({children}, innerRef) => {
    return (<Container fluid={true} ref={innerRef}>{children}</Container>)
});

const NoContainer = React.forwardRef( ({children}, innerRef) => {
    return (<div ref={innerRef}>{children}</div>)
});

const layoutsMapping = {
    narrow_width: NarrowContainer,
    full_width: FullContainer,
    no_container: NoContainer,
};


export const ParagraphsContainer = ({children, id, style, layout, className, state}) => {
    const LayoutComponent = layoutsMapping[layout];
    const isBackgroundSolid = style.backgroundColor ? 'has-background' : null;

    return (
        <div className={classNames(className, isBackgroundSolid)} style={style} id={id}>
            <LayoutComponent>
                {children}
            </LayoutComponent>
        </div>
    )
};
