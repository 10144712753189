import React from "react";
import { Flex, Button } from "vactory-ui";

const ButtonAlphabet = ( props ) => (
  <Button
    sx={{
      width: "42px",
      height: "42px",
      padding: "5px 10px",
      backgroundColor: "transparent",
      color: "lightBlue",
      border: "0",
      textAlign: " center",
      fontSize: " 18px",
      cursor: " pointer",
      display: "block",
      ":hover": {
        color: "darkBlue",
        backgroundColor: "transparent",
      },
    }}
    {...props}
  />
);

const PostsFormFilter = ({ alphabet, handleChange, langue, ...rest }) => {

  return (
    <Flex __css={{
        backgroundColor: "#f6f7f8",
        borderRadius: "small",
        overflow: "hidden",
        padding: "10px 20px",
        justifyContent: "center",
        flexWrap: ['wrap', null, null, 'nowrap'],
      }}
      {...rest} >
      {alphabet.map((node) => {
        return <ButtonAlphabet onClick={() => handleChange(node)} key={node}>
          {node}
        </ButtonAlphabet>;
      })}
    </Flex>
  );
};

export default PostsFormFilter;
