import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Select, Box,
  Row, Col,
} from 'vactory-ui'

const PostsFormFilter = ({ terms, value, sort, handleChange, handleChangeSort }) => {
  const { t } = useTranslation();

  return (
    <Box pt="10px" pb="30px" px="xsmall">
      <Row>
        <Col xs={12} sm={3} md={4} ml="auto">
          <Select
            variant="capitalAzur"
            id="news-category"
            onBlur={null}
            onChange={(e) => handleChange(e.currentTarget.value)}
            defaultValue={value}
            width="100%"
          >
            <option value="all">{t('Tous les thématiques')}</option>
            {terms.map((term) => {
              return (
                <option key={term.id} value={term.id}>
                  {term.label}
                </option>
              )
            })}
          </Select>
        </Col>
        <Col xs={12} sm={3} md={4} mr="auto">
          <Select
            variant="capitalAzur"
            id="news-sort"
            onBlur={null}
            onChange={(e) => handleChangeSort(e.currentTarget.value)}
            defaultValue={sort}
            width="100%"
          >
            <option value="created">{t('Tri Ascendant')}</option>
            <option value="-created">{t('Tri Descendant')}</option>
          </Select>
        </Col>
      </Row>
    </Box>
  )
}

export default PostsFormFilter
