import React from "react";
import {
  Box,
  Flex,
  Heading
} from "vactory-ui";
import { Wysiwyg } from 'vactory-gatsby-ui';


const Card = ( { title, excerpt, ...rest} ) => {
  return (
    <Flex __css={{
        boxShadow: '0 50px 400px 20px rgba(0,0,0,.05)',
        borderRadius: 8,
        background: "white",
        flexDirection: "column",
        overflow: "hidden",
        p: 20,
        pb: 30,
        marginY: 20,
      }} {...rest}>

      <Heading as="h3" __css={{
          color: "#3e4555",
          fontSize: ["24px", null, "29px", null],
          lineHeight: "30px",
          fontWeight: 'semiBold',
          marginBottom: 12,
        }}>{title}</Heading>

      <Box __css={{
          fontSize: "16px",
          lineHeight: "24px",
          color: "#3c3c3c",
        }}>{excerpt}</Box>

    </Flex>
  );
};


const Separator = (props) => <Box
  as="hr"
  __css={{
    border: 0,
    borderTop: '1px solid #dbdbdb',
  }} {...props}/>


export const CardGlossary = (props) => {
  const { posts } = props;
 
  return (
 
      <Box my="medium">
        {posts &&
          posts.map((node,index) => {
            return (<React.Fragment key={index}>
              { index !== 0 && <Separator /> }
              <Card
                title={node.title}
                excerpt={<Wysiwyg html={node.excerpt} />} />
            </React.Fragment>);
          })}
      </Box>

  );
};
