import React from "react"
import {Container} from 'vactory-ui'
import {
    normalizeNodes,
    PostPage,
} from "vactory-gatsby-job-ads"

const PostContainer = ({pageContext: {node}}) => {
  const [normalizedNode] = normalizeNodes([node])
  normalizedNode.pathAlias = node.path.alias

    return <Container>
        <PostPage post={normalizedNode} />
    </Container>
};

export default PostContainer
