import React from "react"
import { Box } from 'vactory-ui'

export const TextContent = (props) => {
    const description = props.description

    return <Box sx={{
            maxWidth: 730,
            paddingTop: 100,
            marginX: 'auto',
            marginBottom: 50,
            fontSize: 16,
            lineHeight: '28px',
            fontWeight: 'regular',
            letterSpacing: .3,
        }}>
        {description}
    </Box>
}