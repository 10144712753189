
export const inputs = {
    variants: {
        capitalAzur: {
            backgroundColor: '#fff',
            border: '1px solid #ced4da',
            padding: '14px 41px',
            ':hover, :active': {

            },
            ':focus': {
                borderColor: '#80beff',
                boxShadow: '0 0 0 4px rgba(1, 124, 254, .25)',
            },
            ':disabled': {},    
        }
    }
}


export const select = {
    capitalAzur: {
        borderRadius: 0,
        flex: '1',
        lineHeight: '26px',
        backgroundColor: '#fff',
    },
    anchors: {
        opacity: 0,
    },
    filters: {
        borderRadius: 0,
        border: '1px solid #cad0d6',
        width: '100%',
    }
}