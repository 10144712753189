import React from "react"
import {Wysiwyg, Picture, Link as GatsbyLink} from "vactory-gatsby-ui"
import {VCC} from "vactory-gatsby-vcc"
import {NextPre} from "vactory-gatsby-nextpre"
import {CardNews, imageLayoutStyles, normalizeNodes, postsQueryParams} from "vactory-gatsby-news"
import {Box, Container, Flex, Heading, Text, Anchor} from "vactory-ui"
import {Tag, ShareTools} from "capital-azur/src/components";
import get from "lodash.get"

const Post = ({uri, post, ...rest}) => {
    const bodyMarkup = <Wysiwyg html={post.body.processed}/>;
    const image = {
        file: get(post, 'field_vactory_media_image.thumbnail.uri.value'),
        alt: get(post, 'field_vactory_media_image.thumbnail.meta.alt'),
    }

    return (
        <>
            <Container>
                <article>
                    <Heading my="medium" variant="heading.articleTitle" level={3}>
                        <Anchor to={post.path.alias} as={GatsbyLink} rel="bookmark">{post.title}</Anchor>
                    </Heading>

                    <Flex sx={{
                        alignItems: 'center',
                        mb: 'medium',
                        py: 'medium',
                        borderBottom: '1px solid rgba(0,0,0,.1)',
                    }} >
                        <Tag sx={{
                            fontWeight: 'light',
                            mx: 10,
                            px: 10,
                            py: 0,
                            textTransform: 'uppercase'
                        }}>{post.field_vactory_taxonomy_1.name}</Tag>
                        <Text sx={{
                            fontSize: 16,
                            lineHeight: '24px',
                            color: '#9B9B9B',
                        }}>{post.field_vactory_date}</Text>
                    </Flex>

                    <Box maxWidth={546}>
                        <Picture
                            {...image}
                            {...imageLayoutStyles.twoColumns}
                            className="card-image"
                        />
                    </Box>

                    <Box py={'15px'}>{bodyMarkup}</Box>

                    <ShareTools />

                </article>
            </Container>
            <VCC
                nid={post.drupal_internal__nid}
                resource={'vactory_news'}
                resourceType={'node--vactory_news'}
                queryParams={postsQueryParams}
                normalizer={normalizeNodes}
                renderNode={
                    node => <CardNews {...node} imageSettings={imageLayoutStyles.threeColumns}/>
                }
            />
            <NextPre
                nid={post.drupal_internal__nid}
                resource={'vactory_news'}
                queryParams={postsQueryParams}
                normalizer={normalizeNodes}
            />
        </>
    )
};

export default Post
