import React, {useCallback} from "react"
import Api from "vactory-gatsby-api"
import get from 'lodash.get';
import qs from "qs"

export const useWebformSubmit = () => {
    console.warn("[Deprecated] useWebformSubmit is deprecated in favor of useWebformSubmitter from vactory-gatsby-webform package.");
    const [webformFetch, setWebformFetch] = React.useState({
        status: "idle",
        response: null,
        error: null,
    });

    const handleWebformRemoteSubmit = useCallback(
        (data) => {
            if (typeof data === "undefined") {
                throw new Error("handleWebformRemoteSubmit missing data param")
            }

            setWebformFetch({
                status: "loading",
                response: null,
                error: null,
            });

            Api.postRest(
                '_webform',
                qs.stringify(data),
                false,
                {
                    headers: {
                        "content-type": "application/x-www-form-urlencoded"
                    }
                }
            )
                .then(function (response) {
                    setWebformFetch({
                        status: "resolved",
                        response: response.data,
                        error: null,
                    })
                })
                .catch(function (error) {
                    const apiError = get(error, "response.data.error");

                    setWebformFetch({
                        status: "rejected",
                        response: apiError,
                        error: error.message,
                    })
                })
        },
        [webformFetch.error, webformFetch.response], // eslint-disable-line react-hooks/exhaustive-deps
    );

    return {
        handleWebformRemoteSubmit,
        webformFetch,
    }
};
