import React from "react";
import { VideoAsk } from "./videoask";
import { AppSettings } from "vactory-gatsby-core";

export const VideoAskContainer = ({ data }) => {
	const screens = data?.components?.[0]?.videoask?.screen_details || [];

	const quizesLength = {};
	const screenIdsMap = {}
	const formattedScreens = screens.map((s, i) => {
		if (s?.quiz?.part_of_quiz) {
			if (!(s?.quiz?.quiz_id in quizesLength))
				quizesLength[s?.quiz?.quiz_id] = 0;
			quizesLength[s?.quiz?.quiz_id]++;
		}

		screenIdsMap[s.id] = i;

		const hasVideo = s?.layout?.background === 'video';
		const hasImage = s?.layout?.background === 'image';

		let quizIndex = parseInt(s?.quiz?.quiz_index);
		return {
			...s,
			response: {
				...s.response,
				...s.response.settings,
			},
			hasVideo,
			hasImage,
			quizIndex,
			quizId: s?.quiz?.quiz_id,
			hasButton: s?.response?.type_response === "button",
			hasQuiz: !!s?.quiz?.part_of_quiz,
			buttonLabel: s?.response?.settings?.label || '',
			answers: s?.response?.settings?.answers || [],
			responseType: s?.response?.type_response,
			allowMultiple: !!s?.response?.settings?.allow_multiple,
			layout: {
				type: s?.layout?.background,
				src: hasVideo ? s?.layout?.url : hasImage ? AppSettings.api.url+s?.layout?.image?.url : null,
				text: s?.layout?.text?.value || '<small class="text-gray-300 font-light italic">empty text</small>'
			}

		};
	});


	console.log('VideoAskContainer', data, screens);
	return (
		<div className="max-w-sm mx-auto">
			<VideoAsk
				screens={formattedScreens}
				quizesLength={quizesLength}
				idsMap={screenIdsMap}
			/>
		</div>
	)
}