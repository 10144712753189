import React, {useEffect} from "react"
import {useForm, Controller} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {
    Box,
    Col,
    Row,
    Button, Flex
} from 'vactory-ui'
import {
    ErrorMessage,
    SuccessMessageLayer,
    MegaFormField,
    Wysiwyg,
} from 'vactory-gatsby-ui'
import {AppSettings, useWebformSubmit} from "vactory-gatsby-core"
import ReCaptcha from "react-google-recaptcha"
import get from 'lodash.get'
import formConfig from "./fields.json"


const getFieldError = (errors, nameChain) => {
    const fieldName = get(formConfig, nameChain)
    return get(errors, fieldName+'.message', null)
}

export const Webform = () => {
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const {handleWebformRemoteSubmit, webformFetch} = useWebformSubmit();
    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const formId = get(formConfig, 'formId');
    const [successLayerDismissed, dismissSuccessLayer] = React.useState(false);

    const onSubmit = data => {
        handleWebformRemoteSubmit(data);
    };

    useEffect(() => {
        if (webformFetch.status === "resolved") {
            reset();
        }
    }, [reset, webformFetch.status]);

    return (<Box py="xxxlarge">

        <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="webform_id" ref={register} value={formId}/>

            <Row>
                <Col xs={12}>
                    <MegaFormField
                        {...get(formConfig, 'fields.0')}
                        error={getFieldError(errors, 'fields.0.name')}
                        fieldRef={register({
                            required: get(formConfig, 'fields.0.required'),
                        })}
                    />
                </Col>

                <Col xs={12}>
                    <MegaFormField
                        {...get(formConfig, 'fields.1')}
                        error={getFieldError(errors, 'fields.1.name')}
                        fieldRef={register({
                            required: get(formConfig, 'fields.1.required'),
                        })}
                    />
                </Col>

                <Col xs={12}>
                    <MegaFormField
                        {...get(formConfig, 'fields.2')}
                        error={getFieldError(errors, 'fields.2.name')}
                        fieldRef={register({
                            required: get(formConfig, 'fields.2.required'),
                            pattern: {
                                value: new RegExp(get(formConfig, 'fields.2.pattern'), 'i'),
                                message: t("Cette adresse mail est invalide"),
                            },
                        })}
                    />
                </Col>

                <Col xs={12}>
                    <MegaFormField
                        {...get(formConfig, 'fields.3')}
                        error={getFieldError(errors, 'fields.3.name')}
                        fieldRef={register}
                    />
                </Col>

                <Col xs={12}>
                    <MegaFormField
                        {...get(formConfig, 'fields.4')}
                        error={getFieldError(errors, 'fields.4.name')}
                        fieldRef={register({
                            required: get(formConfig, 'fields.4.required'),
                        })}
                    />
                </Col>

                <Col xs={12}>
                    <MegaFormField
                        {...get(formConfig, 'fields.5')}
                        error={getFieldError(errors, 'fields.5.name')}
                        fieldRef={register({
                            required: get(formConfig, 'fields.5.required'),
                        })}
                    />
                </Col>
            </Row>


            <Flex justifyContent="flex-end" alignItems="center">
                <Box mx="small">
                    <Controller
                        control={control}
                        name="captcha_response"
                        // rules={{ required: t("Ce champs est requis") }}
                        as={ReCaptcha}
                        hl={currentLanguage}
                        sitekey={AppSettings.keys.reCaptcha}
                        onExpired={ () => setValue("captcha_response", null) }
                        onErrored={ () => setValue("captcha_response", null) }
                    />

                    {errors["captcha_response"] && <ErrorMessage>{errors["captcha_response"].message}</ErrorMessage>}
                </Box>

                <Flex flexDirection="column" justifyContent="flex-start" my="small" px="xsmall">
                    <Button variant="buttons.primary" type={'submit'}>{t('Envoyer')}</Button>
                    {webformFetch.status === "loading" &&
                    <span>Sending data.</span>
                    }
                </Flex>
            </Flex>

        </form>

        {webformFetch.status === "rejected" && 
            <ErrorMessage>
                <b>Erreurs:</b>
                <ul>
                {Object.entries(webformFetch.response).map( ([name, errorMessage], i) => (
                    <li>{errorMessage}</li>
                ) )}
                </ul>
            </ErrorMessage>
        }

        {webformFetch.status === "resolved" && !successLayerDismissed && 
            <SuccessMessageLayer
                title={webformFetch.response.settings.confirmation_title}
                message={ <Wysiwyg html={webformFetch.response.settings.confirmation_message} /> }
                dismiss={dismissSuccessLayer} />
        }

        </Box>
    )
};
