
export const fonts = {
    montserrat: '"Montserrat"',
};
fonts.sans = fonts.montserrat;

export const fontSizes = {
    body: '16px',
 };



export const heading = {
    default: {
        fontSize: ['24px', null, '29px'],
        lineHeight: ['30px', null, '38px'],
        fontWeight: 'extraBold',
    },
    slider: {
        fontSize: ['25px', null, '30px'],
        lineHeight: ['30px', null, '38px'],
        fontWeight: 'extraBold',
    },
    events: {
        fontSize: ['15px', null, '20px'],
        lineHeight: ['24px', null, '29px'],
        fontWeight: 'bold',
        color: 'black',
    },
    banner: {
        fontSize: '29px',
        lineHeight: '40px',
        fontWeight: 'bold',
        letterSpacing: '.55px',
        textTransform: 'uppercase',
    },
    testimonee: {
        fontSize: '15px',
        lineHeight: '20px',
        fontWeight: 'regular',
        textTransform: 'uppercase',
        '&&': {
            paddingLeft: 36,
        },
        '&&::before': {
            width: 20,
            top: 10,
        }
    },
    articleTitle: {
        fontSize: '32px',
        lineHeight: '38px',
        fontWeight: 'bold',
        a: {
            color: 'primary',
            transition: '.2s',
            ":hover": {
                color: 'darkBlue',
            }
        },
    }
};

export const text = {
    slider: {
        fontSize: ['18px', null, '21px'],
        lineHeight: ['23px', null, '25px'],
        fontWeight: 'regular',
    },
    events: {
        fontSize: ['15px', null, '16px'],
        lineHeight: ['22px', null, '24px'],
    }

};