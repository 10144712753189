import React from 'react'
import { CardJobAdsOneRow } from 'vactory-gatsby-job-ads'

const Posts = ({ posts }) => {
  return <>
      {
        posts.map( node => (
          <CardJobAdsOneRow
            key={node.id}
            {...node} />
        ))
      }
  </>
}

export default Posts
