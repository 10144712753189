import React from "react"
import {Box} from "vactory-ui"
import {
    Link as GatsbyLink
} from "vactory-gatsby-ui"

export const CapitalCardButton = (props) => <Box
    as={GatsbyLink}
    to={props.to||props.href}
    variant="buttons.underlined"
    __css={{
        display: 'inline-flex',
        fontSize: "14px",
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '700',
        color: '#2178FF',
        textTransform: 'uppercase',
        border: 0,
        p: 0,
        m: 0,
        pb: '8px',
        background: 'transparent',
        borderBottom: '2px solid',
        '&:hover': {
            cursor: 'pointer'
        }
    }} {...props}/>