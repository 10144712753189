import React from "react"
import {SocialShare, WebShare} from "vactory-gatsby-ui"
import isClient from "is-client"


export const ShareTools = (props) => {

    if (isClient() && typeof navigator.share !== 'undefined')
        return <WebShare {...props} />
    else
        return <SocialShare {...props} />
}
