import React from "react";
// import clsx from "clsx";
import { Icon } from "vactory-ui";
// import {screens} from "./screens";
import { navigate } from "gatsby";
import Api from "vactory-gatsby-api";

const BO_ENDPOINT = "videoask/post";

const BackgroundMedia = ({
	src,
	type,
	videoRef,
	playHanlder,
	isVideoPlaying,
}) => {
	return (
		<>
			<div className="absolute inset-0 bottom-16">
				{type === "image" && (
					<img
						className="absolute inset-0 w-full h-full object-cover transition duration-500 ease-out"
						src={src}
						alt=""
					/>
				)}

				{type === "video" && (
					<video
						className="w-full h-full object-cover"
						src={src}
						ref={videoRef}
						autoPlay={true}
						muted={true}
						loop={true}
						poster="/images/intro.png"
					/>
				)}
			</div>
			{type === "video" && (
				<PlayButton
					playHanlder={playHanlder}
					isVideoPlaying={isVideoPlaying}
				/>
			)}
		</>
	);
};

const PlayButton = ({ playHanlder, isVideoPlaying }) => {
	const anim = React.useRef(null);
	const paths = {
		F: "M0,0 L8,4 L8,12 L0,16 L0,0 M8,4 L16,8 L16,8 L8,12 L8,4",
		L: "M0,0 L5,0 L5,16 L0,16 L0,0 M11,0 L16,0 L16,16 L11,16 L11,0",
	};

	React.useEffect(() => {
		anim.current.beginElement();
	});

	return (
		<div className="z-1 absolute top-1/2 ltr:left-1/2 rtl:right-1/2 -translate-y-full ltr:-translate-x-1/2 rtl:translate-x-1/2 transform">
			<button
				onClick={playHanlder}
				className={
					"bg-white rounded-full cursor-pointer p-7 transition duration-500 " +
					(isVideoPlaying ? "opacity-0 hover:opacity-50" : "")
				}
			>
				<span className="sr-only">play</span>
				<svg className="text-primary w-6 h-6" viewBox="0 0 16 16">
					<path d={paths.F}>
						<animate
							ref={anim}
							attributeName="d"
							fill="freeze"
							dur="0.2s"
							// calc-mode="spline"
							// keySplines="0.2 1 0.2 1"
							from={isVideoPlaying ? paths.F : paths.L}
							to={isVideoPlaying ? paths.L : paths.F}
						/>
					</path>
				</svg>
			</button>
		</div>
	);
};

const Answers = ({ items = [], callback, allowMultiple, defaultChosen }) => {
	const ANIMCLASS = "animate-flash-fast";
	const CLASSNAMES = {
		single: {
			1: 'space-y-3 mt-5 px-4',
			2: 'w-full px-6',
		},
		multiple: {
			1: 'space-y-3 mt-5 px-4 flex flex-wrap -mx-2',
			2: 'flex-grow px-3 mx-1',
		}
	}
	const mode = allowMultiple ? 'multiple' : 'single';

	// manage button click animation
	React.useEffect(() => {
		const eventHandler = (event) => {
			// this get called twice, why?
			event.target.classList.remove(ANIMCLASS);
		};

		window.addEventListener('animationend', eventHandler, true);
		window.addEventListener('animationcancel', eventHandler, true);

		return () => {
			window.removeEventListener('animationend', eventHandler);
			window.removeEventListener('animationcancel', eventHandler);
		};
	}, []);

	const clickHandler = (event, action) => {
		event.target.classList.add(ANIMCLASS);
		// the timeout to allow the animation to finish
		setTimeout(callback, 220, action);
	}

	if (items.length < 1) return null;
	return (
		<div className={CLASSNAMES[mode][1]}>
			{items.map((action, i) => (
				<button
					key={action?.label+i}
					onClick={(e) => clickHandler(e, action)}
					className={[
						i === 0 && "mt-3",
						CLASSNAMES[mode][2],
						"flex justify-between items-center",
						"rounded-5 py-2.5",
						(defaultChosen?.includes?.(action.id))
							? 'bg-primary text-white'
							: 'bg-white hover:bg-primary text-black hover:text-white',
						"ltr:text-left rtl:text-right"
					].join(" ")}
				>
					<span className="text-sm font-bold">{action?.label}</span>
					<svg
						className="flex-none h-3 w-auto ltr:ml-1 rtl:mr-1 "
						viewBox="0 0 47 32"
					>
						<path
							stroke="currentColor"
							strokeWidth="3"
							d="M30.197 0.663c-0.611-0.634-1.628-0.634-2.26 0-0.611 0.613-0.611 1.633 0 2.244l11.459 11.493h-37.813c-0.881 0.001-1.582 0.704-1.582 1.588s0.701 1.61 1.582 1.61h37.813l-11.459 11.472c-0.611 0.634-0.611 1.655 0 2.267 0.632 0.634 1.65 0.634 2.26 0l14.172-14.214c0.632-0.613 0.632-1.633 0-2.244l-14.172-14.216z"
						/>
					</svg>
				</button>
			))}
		</div>
	);
};

const Navigation = ({
	buttonLabel,
	partOf,
	currentPart,
	returnHandler = null,
	buttonHandler = null,
}) => {
	return (
		<>
			{buttonHandler !== null && (
				<div className="flex justify-end mb-5">
					<button
						onClick={buttonHandler}
						className="flex items-center leading-none text-sm font-bold ltr:rounded-l-full rtl:rounded-r-full bg-black bg-opacity-30 border ltr:border-r-0 rtl:border-l-0 border-white border-opacity-30 py-3 ltr:pl-6 rtl:pr-6 ltr:pr-7 rtl:pl-7"
					>
						{buttonLabel}
						<Icon
							name="va-arrow-right"
							className="h-3 ltr:ml-2 rtl:mr-2"
						/>
					</button>
				</div>
			)}

			{(returnHandler || partOf > 1) && (
				<div className="px-12 mb-6">
					{returnHandler !== null && (
						<button
							className="text-white text-sm font-bold mb-4"
							onClick={returnHandler}
						>
							<Icon
								name="va-arrow-left"
								className="h-3 inline-block ltr:mr-2 rtl:ml-2"
								viewBox="0 0 45 32"
								stroke-width="2"
								stroke="currentColor"
							/>
							<span>Retour</span>
						</button>
					)}

					{partOf > 1 && (
						<div className="relative isolate">
							<ul className="flex justify-between">
								{Array(partOf)
									.fill()
									.map((i, p) => (
										<li
											key={p}
											className={
												"cursor-pointer rounded-full box-content w-2 h-2 border-3 hover:bg-primary " +
												(p <= currentPart ? "bg-primary " : "bg-black ") +
												(p < currentPart ? "border-primary" : "border-white")
											}
										/>
									))}
							</ul>

							<div className="-z-1 absolute top-1/2 inset-x-1 h-px bg-white bg-opacity-30" />
						</div>
					)}
				</div>
			)}
		</>
	);
};

const Progress = ({ progress }) => {
	return (
		<div className="mt-7 mb-4 mx-4">
			<progress className="sr-only" max="100" value={progress}>
				{progress}%
			</progress>
			<div className="h-px bg-white bg-opacity-30">
				<div
					className="h-.75 transform -translate-y-px bg-primary transition ease-out w-1/2"
					style={{ width: progress + "%" }}
				/>
			</div>
		</div>
	);
};

const BottomCTA = () => {
	// const [state, dispatch] = React.useContext(LayoutContext);

	const clickHandler = (e) => {
		const block = document.querySelector('.dd-videoask')
		const bottom = block.offsetHeight + block.offsetTop;
		window.scrollTo({ top: bottom, behavior: "smooth" });
		// dispatch({ type: "set", key: "showStickyFooter", value: true });
	}

	return (
		<div className="bg-black mb-8 text-center">
			<a onClick={clickHandler} className="text-white opacity-70" href="#!">
				<span className="text-sm font-light">
					Découvrez le portail TPE
				</span>
				<br />
				<Icon name="va-double-chevron-down" className="h-6 inline" />
			</a>
		</div>
	);
};

export const VideoAsk = ({screens, quizesLength, idsMap}) => {
	const [current, setCurrent] = React.useState(0);
	const [debug, setDebug] = React.useState(true);
	// currScreen does not need to be in state, just 
	const currScreen = screens[current];
	// const [currScreen, setCurrScreen] = React.useState(screens?.[0] || {});
	const [isVideoPlaying, setVideoPlaying] = React.useState(false);
	const scrollableRef = React.useRef(null);
	const [showScrollbar, setShowScrollbar] = React.useState(false);
	const [store, updateStore] = React.useState({});
	const videoRef = React.useRef(null);
	const progress = ((current + 1) / screens.length) * 100;
	const defaultChosen = store[currScreen?.id] || [];

	const saveToStorage = (data) => {
		const STORAGEKEY = "videoask";
		window.localStorage.setItem(STORAGEKEY, JSON.stringify(data));
	}

	const postToBackend = (data) => {
		Api.postRest(BO_ENDPOINT, data, true)
			.then(res => {
				console.log('data has been saved');
				console.log(res);
			})
			.catch(err => {
				console.groupCollapsed(`%cError ${err.response.status}`, 'color: red');
				console.error(err);
				console.groupEnd();
			});
	}

	const getButtonLabel = () => {
		if (currScreen?.allowMultiple && defaultChosen.length > 0)
			return 'done';
		else if (currScreen.hasButton)
			return currScreen.buttonLabel
		else
			return null;
	}

	const getButtonHandler = () => {
		if (currScreen?.allowMultiple && defaultChosen.length > 0)
			return () => setCurrent(current + 1);
		else if (currScreen.hasButton)
			return () => doTheLogic(currScreen?.response);
		else
			return null;
	}

	// const returnHandler = () => setCurrent(current - 1);

	const playHanlder = (e) => {
		if (videoRef.current) {
			if (isVideoPlaying === true) {
				setVideoPlaying(false);
				videoRef.current.pause();
			} else {
				if (videoRef.current.muted) {
					videoRef.current.currentTime = 0;
					videoRef.current.muted = false;
					videoRef.current.volume = 1;
				}
				setVideoPlaying(true);
				videoRef.current.play();
			}
		}
	};

	// record the answer
	const makeRecord = (answer) => {
		const value = answer.id || answer.label;
		let selection = store[currScreen.id] || [];
		const index = selection.indexOf(value);

		if (currScreen.allowMultiple) {
			if (index < 0) { // doesnt exist
				selection.push(value);
			}
			else { // exist, so remove it
				selection.splice(index, 1);
			}
		}
		else {
			selection = [value];
		}

		updateStore({
			...store,
			[currScreen.id]: selection,
		});
	}

	// decide where to go next
	const doTheLogic = (answer) => {
		if (currScreen.allowMultiple)
			return;

		if ('goto' in answer) {
			if (answer.goto === "next" || answer.goto === "") { // next keyword, the default;
				console.log("found next")
				if (current === screens.length - 1) // the last screen;
					alert('you\'ve reached the last screen');
				else
					setCurrent(current + 1);
			}
			else if (answer.goto.startsWith("url:")) { // open url
				console.log("found url")
				navigate(answer.goto.replace(/^url:/, ''));
			}
			else if (answer.goto in idsMap) { // jump to a screen id
				// const idx = parseInt(answer.goto);
				console.log(`found id '${answer.goto}'. corrspnd to array index ${idsMap[answer.goto]}`);
				setCurrent(idsMap[answer.goto]);
			}
			else {
				console.log('no logic')
			}
		}
	}

	const buttonsCallback = (answer) => {
		makeRecord(answer);
		doTheLogic(answer);
	}

	React.useEffect(() => {
		// sync CurrScrn to current
		// setCurrScreen(screens[current]);

		// reset videoPlaying state
		setVideoPlaying(false);

	}, [current]);

	React.useEffect(() => {
		const el = scrollableRef.current;

		if (el === null || el === undefined)
			return;

		if (el.clientHeight < el.scrollHeight - 1) {
			setShowScrollbar(true);
		}
		else {
			setShowScrollbar(false);
		}
	}, [setShowScrollbar]);

	React.useEffect(() => {
		if (current === screens.length - 1) {
			saveToStorage(store);
			postToBackend(store);
		}
	});

	// probably remove in prod?
	if (currScreen === undefined || currScreen === null || current < 0 || current > screens.length-1)
		return <div className="text-4xl text-red-500 text-center">'screen undefined'</div>;

	if (currScreen.quizIndex > quizesLength[currScreen?.quizId])
		currScreen.quizIndex = quizesLength[currScreen?.quizId];

	return (
		<div className="h-exclude-header relative dd-videoask">
			{debug && <div className="absolute bg-white/25 z-50">
				<button onClick={() => setCurrent(current - 1)}>prev</button>
				<span>{current}/{screens.length - 1}</span>
				<button onClick={() => setCurrent(current + 1)}>next</button>
				<button onClick={() => setDebug(!1)}>✕</button>
			</div>}
			{/* video/img background */}
			<BackgroundMedia
				src={currScreen?.layout?.src}
				type={currScreen?.layout?.type}
				playHanlder={playHanlder}
				videoRef={videoRef}
				isVideoPlaying={isVideoPlaying}
			/>

			{/* content */}
			<div
				className="absolute inset-0 flex flex-col justify-end bg-video"
			>
				<div
					ref={scrollableRef}
					className={[
					"text-white transition max-h-[50%] overflow-x-hidden",
					showScrollbar && "overflow-y-auto",
					// hide content when play button is shown
					currScreen?.hasVideo === 'video' && !isVideoPlaying
						? "invisible opacity-0 duration-[0]"
						: "duration-300"
					].join(' ')
				}>
					<div id={`screen-${currScreen?.id}`}>
						<Navigation
							buttonLabel={getButtonLabel()}
							buttonHandler={getButtonHandler()}
							// returnHandler={currScreen.canReturn ? returnHandler : null}
							partOf={currScreen.hasQuiz ? quizesLength[currScreen.quizId] : null}
							currentPart={currScreen.hasQuiz ? currScreen.quizIndex - 1 : null}
						/>

						<div
							className="text-lg font-bold leading-tight text-center px-7"
							dangerouslySetInnerHTML={{ __html: currScreen.layout?.text }}
						/>

						<Answers
							items={currScreen.answers}
							callback={buttonsCallback}
							allowMultiple={currScreen.allowMultiple}
							defaultChosen={defaultChosen}
						/>
					</div>
				</div>

				<Progress progress={progress} />

				<BottomCTA />
			</div>
		</div>
	);
};
