import React from 'react'
import get from 'lodash.get'
import { Wysiwyg } from 'vactory-gatsby-ui'
// import {stripHtml, truncate} from 'vactory-gatsby-core'
import {EventsSection} from './slider';
import {normalizeDFNodes} from "vactory-gatsby-events";

export const SliderContainer = ({data}) => {
    const nodes = get(data, 'components.0.views.data.nodes', []);
    const title = get(data, 'components.0.title', '')
    const intro = get(data, 'components.0.description.value.#text', null)
    const action = {
      href: get(data, 'components.0.link.url', null),
      label: get(data, 'components.0.link.title', ''),
    }
    const events =  normalizeDFNodes(nodes, 100);

    return <EventsSection
    title={title}
    intro={ intro && <Wysiwyg html={intro} /> }
    action={action}
    events={events} />
};
