import React from 'react';
import {
    Anchor,
    Icon,
} from 'vactory-ui'


export const SocialIcon = ({name, link='#', size="24px", ...rest}) => {
    return <Anchor href={link} sx={{
        mr: 16,
        color: 'primary',
        transition: '.3s',
        '&:hover': {
            color: 'black',
        },
        }} {...rest}>
        <Icon name={name} size={size} />
    </Anchor>
}