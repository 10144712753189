import React from 'react'
import {
  Box,
  Container,
} from 'vactory-ui'
import { TestimonialCard } from '../../components/TestimonialCard'


export const TestimonialWidget = (props) => {

  return <Box py={[32, null, 100]}>
    <Container>

      <TestimonialCard
        name={props.name}
        title={props.title} 
        role={props.role}
        description={props.description}
        image={props.image} />

    </Container>
  </Box>
}
