import React from 'react'
import { ImageAndTextSection } from '../../components/ImageAndTextSection'

export const ContentWithBg = (props) => {

    return <ImageAndTextSection
        title={props.title}
        body={props.description}
        image={props.image}
        link={{
            href: props.link,
            label: props.link_label
        }}
        color={props.color}
        mode={props.mode} />
}
