import React from 'react';
import {
    Box,
    Container,
    Flex,
    Image,
    Link,
} from 'vactory-ui';
import {
    Link as GatsbyLink
} from 'vactory-gatsby-ui';
import { DashHeading } from './Headings';

export const ImageAndTextSection = ({image, title, body, link, mode=0, color, ...rest}) => {

    const titleDashColor = color === 'black' ? 'lightBlue' : color;

    return <Box sx={{
        color: color,
    }} {...rest}>
        <Container>
            <Flex sx={{
                maxWidth: ['90%', null, null, 'unset'],
                mx: ['auto', null, null, '0'],
                pt: 75,
                flexWrap: 'wrap',
            }}>
                <Box sx={{
                    flexBasis: ['100%', null, null, '50%'],
                    pr: [null, null, null, 15],
                    order: [1, null, null, mode === 1 ? 1 : 0],
                }} >
                    <Image src={image} />
                </Box>

                <Flex sx={{
                    flexDirection: 'column',
                    flexBasis: ['100%', null, null, '50%'],
                    pb: [25, null, null, 75],
                    pl: [null, null, null, 15],
                    }} >

                    <DashHeading level="2" dashColor={titleDashColor}>{title}</DashHeading>
                    <Box sx={{
                        mt: 30,
                        fontSize: 'paragraph',
                        lineHeight: '28px',
                        letterSpacing: .3,
                    }}>{body}</Box>
                    { ( link.href || link.label ) && <Link sx={{
                            variant:'buttons.white',
                            mt: 30,
                            alignSelf: 'flex-start',
                        }}
                        borderRadius={null} // remove if c4d7ce17f1f180b04cfcb305471635e8cefd0193 if merged
                        as={GatsbyLink}
                        to={link.href}>{link.label}</Link>
                    }
                </Flex>
            </Flex>
        </Container>
    </Box>
}


