
import * as buttons from "./buttons"
import * as colors from "./colors"
import * as gridSystem from "./gridSystem"
import * as inputs from "./inputs"
import * as layouts from "./layouts"
import * as typography from "./typography"
//  ./forms.js is intentionally not imported


export const theme = {
    ...buttons,
    ...colors,
    ...gridSystem,
    ...inputs,
    ...layouts,
    ...typography,
};
