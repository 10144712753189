import React from "react"
import {CardEvents, imageLayoutStyles, normalizeNodes, postsQueryParams} from "vactory-gatsby-events";
import {VCC} from "vactory-gatsby-vcc";
import {
    Container,
    Heading,
    Anchor,
    Flex,
    Text,
    Box,
} from "vactory-ui"
import {
    Link as GatsbyLink,
    Picture,
    SocialShare,
    WebShare,
    Wysiwyg,
} from "vactory-gatsby-ui"
import {Tag, TimeSpanBadge} from "capital-azur/src/components"
import {useTranslation} from "react-i18next"
import get from "lodash.get"

const Post = ({post}) => {
    const {t} = useTranslation();

    const image = {
        file: get(post, 'field_vactory_media_image.thumbnail.uri.value'),
        alt: get(post, 'field_vactory_media_image.thumbnail.meta.alt'),
    }

    return (
        <>
            <Container>
                <article>
                    <Heading my="medium" variant="heading.articleTitle" level={3}>
                        <Anchor to={post.path.alias} as={GatsbyLink} rel="bookmark">{post.title}</Anchor>
                    </Heading>

                    <Flex sx={{
                        alignItems: 'center',
                        mb: 'medium',
                        py: 'medium',
                        borderBottom: '1px solid rgba(0,0,0,.1)',
                    }} >
                        <Tag color="darkBlue" fontWeight="light">
                            {post.field_vactory_taxonomy_1.name}
                        </Tag>
                        <Tag color="redTag" fontWeight="light">
                            {post.field_vactory_taxonomy_2.name}
                        </Tag>
                        <Text sx={{
                            fontSize: 16,
                            lineHeight: '24px',
                            color: '#9B9B9B',
                        }}>{post.field_vactory_date}</Text>
                    </Flex>


                    <Box sx={{position: 'relative'}} maxWidth={546}>
                        <TimeSpanBadge
                            start={post.field_vactory_date_interval.value}
                            end={post.field_vactory_date_interval.end_value}
                            sx={{zIndex: 1}}
                        />
                        <Picture
                            {...image}
                            {...imageLayoutStyles.twoColumns}
                            className="card-image"
                        />
                    </Box>

                    <Box py={'15px'}>
                        <Wysiwyg html={post.body.processed}/>
                    </Box>

                    <SocialShare/>

                    <WebShare/>
                </article>
            </Container>

            <VCC
                nid={post.drupal_internal__nid}
                title={t('Événements similaires')}
                resource={'events'}
                resourceType={'node--events'}
                queryParams={postsQueryParams}
                normalizer={normalizeNodes}
                renderNode={
                    node =>  <CardEvents {...node} imageSettings={imageLayoutStyles.threeColumns} />
                }
            />
        </>
    )
}

export default Post
