import React from 'react'
import {
  Box,
  Container,
  Flex,
  Link,
  Paragraph,
} from 'vactory-ui'
import {
  Link as GatsbyLink
} from 'vactory-gatsby-ui';
import { DashHeading } from '../../components/Headings'
import { Gouvernant } from '../../components/GouvernantCard'

export const GouvernanceWidget = ({title, intro, gouvernants, action}) => {
  return <Box sx={{
    py: 70,
  }}>
    <Container>
      <Box variant='boxes.intro'>
          {title && <DashHeading mb={30}>{title}</DashHeading>}
          {intro && <Paragraph as='div'>{intro}</Paragraph>}
      </Box>

      <Flex flexWrap='wrap' mx={-15} justifyContent='space-between'>
          {gouvernants.map( (gvrnant, i) => <Gouvernant
              key={i}
              image={gvrnant.image}
              name={gvrnant.title}
              link={gvrnant.link}
              />
          )}
      </Flex>

      { (action && 'label' in action ) && <Link sx={{
              mt: 70,
              mx: 'auto',
              width: 'fit-content',
              display: 'flex',
              variant: 'buttons.white',
          }}
          as={GatsbyLink}
          to={action.href} >
              {action.label}
          </Link>
      }
    </Container>
  </Box>
}
