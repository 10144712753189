import React from 'react'
import {
  Box,
  Flex,
  Icon,
  Text,
} from 'vactory-ui'
import { DashHeading } from './Headings'
import { Picture } from 'vactory-gatsby-ui'


const imageSettings = {
    sizes: [
        {
            name: "decoupled_image_168_168",
            media: "(min-width: 0)",
        },
    ],
    width: 168,
    height: 168,
    ratio: 1,
}
export const TestimonialCard = ({ name, title, role, description, image, ...rest }) => {

  return <Box sx={{
    maxWidth: 920,
    mx: 'auto',
    boxShadow: '0 20px 60px 0 rgba(25, 104, 252, .1)',
    bg: 'white',
    p: 38,
    position: 'relative',
    borderRadius: 8,
    overflow: 'hidden',
  }}>
    <Flex mx={-15} sx={{
      flexDirection: ['column', null, 'row'],
      flexWrap: ['wrap', null, 'no-wrap'],
    }}>
      <Flex width={[1, null, 1/4]} sx={{
        px: 15,
        mb: [10, null, 0],
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Box sx={{
          borderRadius: 8,
          overflow: 'hidden',
          width: 168,
          height: 168,
        }}>
          <Picture
            file={image}
            alt={name}
            {...imageSettings}
          />
        </Box>
        <DashHeading mt={[15, null, 28]} mb={0} variant="heading.testimonee">{name},</DashHeading>
        <Text as="span" sx={{
            fontSize: '12px',
            lineHeight: '16px',
            color: 'primary',
        }}>{role}</Text>
      </Flex>
      <Box width={[1, null, 3/4]} px={15}>
        <DashHeading >{title}</DashHeading>
        <Box>{description}</Box>
        <Icon name="quote-right" size={200} sx={{
            color: "primary",
            opacity: .21,
            position: 'absolute',
            bottom: -40,
            right: 0,
            pointerEvents: 'none',
            }} />
      </Box>
    </Flex>
  </Box>
}
