import React from "react"
import {default as BaseLoadingOverlay} from 'react-loading-overlay';
import {useTranslation} from "react-i18next"
import styled from "styled-components"

const StyledLoadingOverlay = styled(BaseLoadingOverlay)``;

export const LoadingOverlay = ({children, ...rest}) => {
    const {t} = useTranslation();
    return (
        <StyledLoadingOverlay
            spinner
            text={t('Chargement du contenu ...')}
            styles={{
                wrapper: base => ({
                    ...base,
                    minHeight: 200,
                }),
                overlay: base => ({
                    ...base,
                    background: 'rgba(0, 0, 0, 0.46)',
                }),
            }}
            {...rest}
        >
            {children}
        </StyledLoadingOverlay>
    )
};
