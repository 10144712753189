import React from 'react';
import { ThemeContext } from 'styled-components';
import { Waypoint } from 'react-waypoint';
import { CountUp } from 'countup.js';
import {
    Box,
    Slider,
    Text,
} from 'vactory-ui';


export const KeyNumber =({number, suffix, description, ...rest}) => {
    const theNumber = React.createRef()

    const startCount = () => {
        setTimeout(
            () => theNumber.current.counter.start(),
            500
        );
    }
    
    React.useEffect(() => {
        const counter = new CountUp(
            theNumber.current,
            number,
            {
                suffix,
            },
        );
        theNumber.current.counter = counter

    }, [theNumber, number, suffix])

    return (
        <Waypoint onEnter={startCount}>
            <Box sx={{
                border: '1px solid',
                borderColor: 'lightBlue',
                borderRadius: 8,
                padding: '54px 43px 35px',
                bg: 'white',
                mx: 15,
                textAlign: 'center',
                maxWidth: 275,
            }} {...rest}>

                <Box ref={theNumber} as='span' sx={{
                    display: 'block',
                    fontSize: 100,
                    lineHeight: '124px',
                    fontWeight: 'black',
                    color: 'primary',
                    WebkitTextFillColor: 'white',
                    WebkitTextStroke: '4px currentColor',
                    mb: 24,
                }}>{number}{suffix}</Box>

                <Text as='span' sx={{
                    fontSize: 16,
                    lineHeight: '24px',
                    fontWeight: 'semiBold',
                    color: '#3e4555',
                }}>{description}</Text>

            </Box>
    </Waypoint>
    )
}

export const KeyNumbers = ( {numbers, ...rest} ) => {
    const theme = React.useContext(ThemeContext);

    const settings = {
        responsive: [
            {
                breakpoint: parseInt(theme.breakpoints.lg), // 992
                settings: {
                    dots: false,
                    arrows: false,
                    infinite: true,
                    slidesToShow: 1,
                    centerMode: true,
                    slidesToScroll: 1,
                    variableWidth: true,
                    focusOnSelect: true,
                    centerPadding: '0px',
                }
            },
            {
                breakpoint: 99999, // 
                settings: 'unslick',
            }
        ]
    }

    return (
        <Box>
            <Slider
                {...settings}
                __css={{
                    '.slick-slide > div': { textAlign: 'center' },
                    '&.regular': {
                        display: 'flex',
                        justifyContent: 'space-around',
                    }
                }}
            >
                { numbers.map( (item, i) => (
                    <KeyNumber
                        key={i}
                        number={item.chiffre}
                        suffix={item.chiffre_alt}
                        description={item.title}
                    />
                    )
                )}
            </Slider>
        </Box>
    )
}