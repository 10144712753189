import React from "react"
import {
    BannersTemplate,
    StatePageSection,
    OfflineDetector,
} from 'vactory-gatsby-ui'
import { ThemeContext } from 'styled-components';
import {
    CapitalAzurHeader,
    CapitalAzurFooter,
    SocialMediaToolbox,
    FloatingToolbox,
    BackToTopButton,
} from "capital-azur/src/components"
import { useMedia } from 'vactory-ui';

export const DefaultLayout = (props) => {
    const {children, pageContext: {node, breadcrumb}} = props
    const theme = React.useContext(ThemeContext);
    const upLg = useMedia(`(min-width: ${theme.breakpoints.lg})`)

    return (
        <>
            <StatePageSection.Provider>
            <CapitalAzurHeader location={props.location} />

            {node.internal_node_banner &&
            <BannersTemplate
                widget={node.internal_node_banner}
                node={node}
                breadcrumbItems={(breadcrumb && breadcrumb.length > 0) ? breadcrumb : [] }/>
            }
            <main>
                {children}
            </main>
            </StatePageSection.Provider>
            <CapitalAzurFooter />
            {upLg && <SocialMediaToolbox />}
            {upLg && <FloatingToolbox />}
            <BackToTopButton />
            <OfflineDetector />
        </>
    )
}
