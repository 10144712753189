import React from "react"
import {useTranslation} from "react-i18next"
import {
    Box,
    Button,
    Heading,
    Paragraph,
} from 'vactory-ui'
import {
    Link,
    Wysiwyg,
} from 'vactory-gatsby-ui'

const Post = ({post}) => {
    const formUrl = post.pathAlias + '/form/';
    const {t} = useTranslation();

    return (
        <Box>
            <Heading level="3">{post.title}</Heading>

            <Heading mt={20} mb={10} level="4">{t('Poste')}</Heading>
            <Box>
                <Wysiwyg html={post.jobDescription} />
            </Box>

            <Heading mt={20} mb={10} level="4">{t('Profil recherché')}</Heading>
            <Box>{post.profileDescription}</Box>

            <Heading mt={20} mb={10} level="4">{t('Métier')}</Heading>
            <Box>{post.profession}</Box>

            <Heading mt={20} mb={10} level="4">{t('contrat')}</Heading>
            <Box>{post.contract}</Box>

            <Heading mt={20} mb={10} level="4">{t('Ville')}</Heading>
            <Box>{post.city}</Box>

            <Heading mt={20} mb={10} level="4">{t('Date de publication')}</Heading>
            <Box>{post.date}</Box>

            <Heading mt={20} mb={10} level="4">{t('Coordonnées du recruteur')}</Heading>
            <Box>
                <Paragraph>{post.recruiterAddress}</Paragraph>
                <Paragraph>{post.recruiterEmail}</Paragraph>
                <Paragraph>{post.recruiterPhone}</Paragraph>
            </Box>

            <Button mt={30} variant="buttons.primary" as={Link} to={formUrl}>{t('Postuler')}</Button>
        </Box>
    )
}

export default Post
