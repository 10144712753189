import React from 'react'
import {Text} from 'vactory-ui'


export const Tag = ({color, ...props}) => <Text
    __css={{
        color: 'white',
        bg: color || 'darkBlue',
        fontSize: 10,
        fontWeight: 'bold',
        lineHeight: '20px',
        borderRadius: 20,
        py: 0,
        px: 10,
        mr: 10,
    }} {...props} as='span' />