import React from "react";
import {useTranslation} from "react-i18next";
import { Box, Flex, Heading } from "vactory-ui";
import { Picture, Wysiwyg, Link as GatsbyLink } from "vactory-gatsby-ui";
import { Tag, TimeSpanBadge, CapitalCardButton } from "capital-azur/src/components";


const Card = props => <Box
	__css={{
		background: "white",
		display: "flex",
		flexDirection: "column",
		borderRadius: "small",
		overflow: "hidden",
		fontFamily: "montserrat",
		marginBottom: "16px",
		boxShadow: 2,
		width: "100%",
	}} {...props} />


export const CardEvents = ({
	title,
	image,
	dateInterval: {
		value: starData,
		end_value: endDate,
	},
	category,
	city,
	excerpt,
	url,
	imageSettings=null,
	...rest}) => {

	const {t} = useTranslation();

	return <Card {...rest}>
		<Box position="relative">
			<TimeSpanBadge
				start={starData}
				end={endDate}
				sx={{zIndex: 1}} />
			<Picture
				file={image}
				sizes={imageSettings.sizes}
				alt={title}
				width={imageSettings.width}
				height={imageSettings.height}
				ratio={imageSettings.ratio}
				className="card-image" />
		</Box>
		<Box p="medium">
			<Heading level="3">{title}</Heading>
			<Flex mb="16px">
				<Tag color="darkBlue">{category}</Tag>
				<Tag color="redTag">{city}</Tag>
			</Flex>
			<Box fontSize="14px" color="black800">
				<Wysiwyg html={excerpt} />
			</Box>
		</Box>
		<Box p="medium" mt="auto">
			<CapitalCardButton as={GatsbyLink} to={url}>
				{t('Read more')}
			</CapitalCardButton>
		</Box>
	</Card>
};
