import React from 'react';
import {
    Box,
    Container,
    Flex,
    Link,
    Paragraph,
} from 'vactory-ui';
import { Picture } from "vactory-gatsby-ui"
import { DashHeading } from './Headings';
import { CapitalCardButton } from './Buttons';


const CapitalCardTitle = ( props ) => <Box
    as='h3'
    __css={{
        fontSize: ['16px', null, '20px', null],
        lineHeight: ['24px', null, '28px', null,],
        fontWeight: 600,
        letterSpacing: -.4,
        marginBottom: '16px',
    }}
    {...props}
    />

const CardBody = ( props ) => <Box
    __css={{
        p: 'medium',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    }}
    {...props}
    />

const Card = ( props ) => <Box
    __css={{
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        overflow: 'hidden',
        marginBottom: '16px',
        flexGrow: 1,
        margin: 15,
        boxShadow: 'cards',
        // minHeight: '421px'
    }}
    {...props}
    />

const CapitalCardTag = ( props ) => <Box
    __css={{
        display: 'inline-flex',
        fontSize: "9px",
        fontWeight: '600',
        backgroundColor: 'primary800',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 'rounded',
        textTransform: 'uppercase',
        py: 'xsmall',
        px: 'medium',
        color: 'white',
    }} 
    {...props}
    />

const CapitalCardDate = ( props ) => <Box
    __css={{
        display: 'inline-flex',
        fontSize: "15px",
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '500',
        color: '#9B9B9B',
        ml: '10px'
    }}
    {...props}
    />


export const InsightCard = ({tag, date, image, title, link, link_label, imageSettings, ...rest}) => {
    return <Flex flexDirection="column" width={[1, 1/2, null, 1/3]} {...rest}>
            <Card>
                <Box>
                    <Picture
                        file={image}
                        alt={title}
                        {...imageSettings}
                        className="card-image"
                    />
                </Box>
                <CardBody>
                    <Flex mb="16px">
                        <CapitalCardTag>{tag}</CapitalCardTag>
                        <CapitalCardDate>{date}</CapitalCardDate>
                    </Flex>
                    <CapitalCardTitle>{title}</CapitalCardTitle>
                    <CapitalCardButton href={link}>{link_label}</CapitalCardButton>
                </CardBody>
            </Card>
        </Flex>
}

export const Insights = ({title, intro, cards, action={label: 'Voir tous les insights', href: '://'}, ...rest}) => {
    return <Box sx={{
        py: 70,
        backgroundImage: 'linear-gradient(72.21deg, #F4F8F8 0%, #FFFFFF 100%)'
    }}>
        <Container>
                <Box variant='boxes.intro'>
                    <DashHeading mb={30}>{title}</DashHeading>
                    <Paragraph>{intro}</Paragraph>
                </Box>

                <Flex flexWrap='wrap' mx={-15}>
                    {cards}
                </Flex>

                <Link sx={{
                    mt: 70,
                    mx: 'auto',
                    width: 'fit-content',
                    display: 'flex',
                    variant: 'buttons.white',
                }} href={action.href}>{action.label}</Link>
        </Container>
    </Box>
}