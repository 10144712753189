import React from 'react';
import get from 'lodash.get';
import {Helmet} from "react-helmet";
import {Head} from 'vactory-gatsby-core'
import {
    theme as vactoryTheme,
    // ColorModeProvider,
    DirectionManager,
    GlobalStyle,
    mergeIcons,
    //iconSet,
    VactoryIconProvider
} from 'vactory-ui';
import {ThemeProvider, createGlobalStyle} from 'styled-components';
import deepmerge from 'deepmerge';
import {DefaultLayout as Layout} from '../layouts';
import {theme as UiTheme} from '../theme';
import customIconSet from '../icons/custom-icons.json';
import videoAskIcons from "../icons/video-ask-icons.json";

const theme = deepmerge.all([vactoryTheme, UiTheme]);
theme.breakpoints = vactoryTheme.breakpoints;
// workaround for https://github.com/voidagency/factory-react-ui/commit/0645cf292913c8c79502b2aa5c16445fd7465ff4#r43794986
theme.heading.default = UiTheme.heading.default;

// const customIcons = mergeIcons(iconSet, customIconSet);
const customIcons = mergeIcons(customIconSet, videoAskIcons);


const VactoryGlobaltStyle = createGlobalStyle`
    html {
        scroll-behavior: smooth;
    }

    a {
    text-decoration: none;
    }
`;
export const wrapPageElement = ({element, props}) => {
    const node = get(props, 'pageContext.node');
    let dir = 'ltr'
    let settings = {};

    if (node) {
        dir = node.langcode === 'ar' ? 'rtl' : 'ltr';

        if (node.node_settings && node.node_settings.length > 0) {
            try {
                settings = JSON.parse(node.node_settings);
            } catch(e) {
                console.warn("[Node] you passed a wrong JSON String to node_settings.")
            }
        }
    }

    if (node) {
        return (
            <ThemeProvider theme={theme}>
                <VactoryIconProvider value={customIcons}>
                    {/* <ColorModeProvider> */}
                        <DirectionManager dir={dir}>
                            <GlobalStyle/>
                            <VactoryGlobaltStyle/>
                            <Head
                                title={props.pageContext.title}
                                lang={node.langcode}
                                meta={node.metatag_normalized}/>
                            {props.path === `/${node.langcode}/video-ask` && (
                                <Helmet
                                    htmlAttributes={{
                                        id: "digidam",
                                    }}
                                    link={[
                                        { rel: "stylesheet", href: "/vask-styles.css" }
                                    ]}
                                />
                            )}
                            <Layout nodeSettings={settings} {...props}>{element}</Layout>
                        </DirectionManager>
                    {/* </ColorModeProvider> */}
                </VactoryIconProvider>
            </ThemeProvider>
        )
    } else {
        return null
    }
};
