const imageStyles = [{
  name: "events_image_480_314",
  width: 480,
  height: 314,
  operation: "scale_crop"
}, {
  name: "events_image_768_503",
  width: 768,
  height: 503,
  operation: "scale_crop"
}, {
  name: "events_image_675_442",
  width: 675,
  height: 442,
  operation: "scale_crop"
}, {
  name: "events_image_546_358",
  width: 546,
  height: 358,
  operation: "scale_crop"
}, {
  name: "events_image_359_235",
  width: 359,
  height: 235,
  operation: "scale_crop"
}, {
  name: "news_image_480_314",
  width: 480,
  height: 314,
  operation: "scale_crop"
}, {
  name: "news_image_768_503",
  width: 768,
  height: 503,
  operation: "scale_crop"
}, {
  name: "news_image_675_442",
  width: 675,
  height: 442,
  operation: "scale_crop"
}, {
  name: "news_image_546_358",
  width: 546,
  height: 358,
  operation: "scale_crop"
}, {
  name: "news_image_359_235",
  width: 359,
  height: 235,
  operation: "scale_crop"
}, {
  name: "next_prev_image_114_114",
  width: 114,
  height: 114,
  operation: "scale_crop"
}, {
  name: "decoupled_image_424_238",
  width: 424,
  height: 238,
  operation: "scale_crop"
}, {
  name: "decoupled_image_168_168",
  width: 168,
  height: 168,
  operation: "scale_crop"
}, {
  name: "gvrn_image_355_200",
  width: 355,
  height: 200,
  operation: "scale_crop"
}, {
  name: "gvrn_image_195_165",
  width: 195,
  height: 165,
  operation: "scale_crop"
}, {
  name: "gvrn_image_215_120",
  width: 215,
  height: 120,
  operation: "scale_crop"
}, {
  name: "gvrn_image_524_295",
  width: 524,
  height: 295,
  operation: "scale_crop"
}, {
  name: "events_image_454_303",
  width: 454,
  height: 303,
  operation: "scale_crop"
}, {
  name: "events_image_217_258",
  width: 217,
  height: 258,
  operation: "scale_crop"
}, {
  name: "events_image_210_258",
  width: 210,
  height: 258,
  operation: "scale_crop"
}, {
  name: "events_image_270_234",
  width: 270,
  height: 234,
  operation: "scale_crop"
}];
export default imageStyles;