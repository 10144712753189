
export const navs = {
    ancre: {
        alignItems: 'start',
        item: {
            fontSize: '11px',
            lineHeight: '32px',
            fontWeight: 500,
            color: '#000000',
            textTransform: 'uppercase',
            px: '16px',
            py: ['16px', '0'],
            '&:not(:last-child)': {
                borderRight: '1px solid #cccccc',
            },
            '&:hover': {
                color: 'primary',
            },
            '&.is-current': {
                color: 'primary',
                borderBottom: '4px solid #007732',
            },
            a: {
                display: 'block',
                font: 'inherit',
                color: 'inherit',
            }

        },
        activeNav: {
            color: '#017CFE',
            fontWeight: 600,
            ':hover': {
                color: '#017CFE'
            }
        },
    }
};



export const boxes = {
    intro: {
        mx: [0, null, 100, 180],
        mb: 55,
        '&:empty': {
            display: 'none'
        }
    }
}


export const layer = {
    overlay: {
        opacity: '.68 !important',
        backgroundColor: 'black',
    }
}